<template>
  <div class="error-page">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="error p-5">
            <b-icon-exclamation-triangle-fill class="error__img" />
            <h1>Oops! That page can’t be found.</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./404.scss";

export default {
  name: "ErrorPage",
  components: {},
  metaInfo: {
    title: "Page not found!",
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
